import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { useSetOfferSchedule } from '@genoa/middle-end'
import { GENERIC_ERROR_MODAL_CONTENT } from '@genoa/screen-content'
import { calculatePaymentDays, formatter, toFormattedDollars } from '@genoa/utils'

import { useAuthState } from '../../../../../contexts'
import {
  useAutopilotEligibility,
  usePaymentStatus,
  useReduxAction,
  useReduxSelector,
  useShowErrorMessageModal,
} from '../../../../../hooks'
import { RootState } from '../../../../../modules'
import { OfferState, setRepaymentDayAction } from '../../../../../modules/flex2/offer'
import { useAnalytics } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../../components'
import { useSetScheduleRange } from '../../../onboarding/use-set-schedule-range'
import { CreditBuilderCustomizeSchedule } from './CreditBuilderCustomizeSchedule'

export interface CreditBuilderCustomizeScheduleContainer {
  analyticsScreenName: Analytics.Screens
  onNext: () => void
}

export enum PaymentDays {
  FIRST_DAY = 'firstDay',
  SECOND_DAY = 'secondDay',
}

export type DaySelectedState = {
  firstDay: number | undefined
  secondDay: number | undefined
}

export const CreditBuilderCustomizeScheduleContainer = (props: CreditBuilderCustomizeScheduleContainer) => {
  const { user } = useAuthState()
  const [daySelected, setDaySelected] = useState<number | undefined>()
  const [schedule, setSchedule] = useState<number[]>([])
  const [isLoadingRefetch, setIsLoadingRefetch] = useState(true)
  const [displayError, setDisplayError] = useState(false)
  const offerState: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const setRepaymentDay = useReduxAction(setRepaymentDayAction)
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { isUserEligibleForAutopilot, isLoadingEligibility } = useAutopilotEligibility()
  const { setScheduleRange } = useSetScheduleRange({
    componentName: 'CreditBuilderCustomizeScheduleContainer',
  })

  const { showErrorMessage } = useShowErrorMessageModal()

  const [{ loading }, setOfferSchedule] = useSetOfferSchedule()

  const {
    deposit,
    error: paymentStatusError,
    isLoading: isLoadingPaymentStatus,
    isFetching: isFetchingPaymentStatus,
  } = usePaymentStatus()

  const rentAmountFormatted = useMemo(() => {
    return toFormattedDollars(deposit?.total_amount_cents) || ''
  }, [deposit])

  useEffect(() => {
    if (!offerState?.offer?.offer_id) {
      return
    }
    setScheduleRange(setSchedule)
  }, [offerState?.offer?.offer_id])

  useEffect(() => {
    analytics.logScreenView(props.analyticsScreenName)
  }, [])

  useEffect(() => {
    if (!daySelected) {
      return
    }
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_SELECT_DAY, {
      day: daySelected,
    })
  }, [daySelected])

  const paymentDays = useMemo(() => {
    if (schedule.length === 0) {
      return []
    }
    const [startDay, endDay] = schedule
    return calculatePaymentDays(startDay, endDay)
  }, [schedule])

  const handleSelectPaymentDay = (day: number) => {
    setDaySelected(day)
  }

  const handleHideError = () => {
    if (daySelected) {
      setDisplayError(false)
    }
  }

  const handleDisplayError = () => {
    if (!daySelected) {
      setDisplayError(true)
      return true
    }
  }

  useEffect(() => {
    handleHideError()
  }, [daySelected])

  const handleClickConfirmButton = async () => {
    if (handleDisplayError()) {
      analytics.logEvent(Analytics.Events.SLC_CUSTOMIZE_SCHEDULE_CTA_ERROR)

      return
    }
    if (!daySelected || !user?.uid) {
      return
    }
    analytics.logEvent(Analytics.Events.SLC_CUSTOMIZE_SCHEDULE_CTA)
    const data = await setOfferSchedule({
      simple_one_repayment_schedule: { repayment_day: daySelected },
      offerId: offerState.offer.offer_id,
      customerPublicId: user.uid,
    })
    if (data.status === 200) {
      setRepaymentDay(daySelected)
      props.onNext()
    } else {
      showErrorMessage(GENERIC_ERROR_MODAL_CONTENT.TITLE, GENERIC_ERROR_MODAL_CONTENT.SUBTITLE)
    }
  }
  if (paymentStatusError) {
    navigate(Routes.App.TRY_AGAIN)
    return <></>
  }

  if (isLoadingPaymentStatus || isFetchingPaymentStatus) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <>
      <CreditBuilderCustomizeSchedule
        onClickConfirmButton={handleClickConfirmButton}
        loading={loading}
        showApprovedModal={!isUserEligibleForAutopilot}
        rentAmountFormatted={rentAmountFormatted}
        daySelected={daySelected}
        paymentDays={paymentDays}
        onSelectPaymentDay={handleSelectPaymentDay}
        displayError={displayError}
        formatter={formatter}
      />
    </>
  )
}
