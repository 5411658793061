import React from 'react'
import { PREFILL_ADDRESS } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { LinkText, SmallText } from '../../../../components'

interface PrefillsConfirmUnitBannerProps {
  readonly onReset: () => void
  readonly propertyName: string
}

const StartOverLinkText = (props: Required<PrefillsConfirmUnitBannerProps>) => {
  return (
    <LinkText fontSize="16px" textAlign="left" onClick={props.onReset}>
      <b>{PREFILL_ADDRESS.START_OVER}</b>
    </LinkText>
  )
}

export const PrefillConfirmUnitBanner = (props: PrefillsConfirmUnitBannerProps) => {
  return (
    <PrefillsBanner flexShrink={0} direction="column" bg="cloud">
      <SmallText fontSize="16px" textAlign="left" alignSelf="stretch" data-testid="PrefillConfirmUnitBannerBody">
        {PREFILL_ADDRESS.BODY_UNIT_ONE}
        <SmallText fontSize="16px" fontWeight="bold">
          {props.propertyName}
        </SmallText>
        {PREFILL_ADDRESS.BODY_UNIT_TWO}
      </SmallText>
      <Box>
        {PREFILL_ADDRESS.NOT_RIGHT} <StartOverLinkText {...props} />
      </Box>
    </PrefillsBanner>
  )
}

const PrefillsBanner = styled(Flex)`
  padding: 16px;
  gap: 12px;
  border-radius: 16px;
  bg: cloud;
`
