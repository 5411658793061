import { OnboardingAutopayToggleExperiment } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

export const useOnboardingAutopayToggleExperiment = () => {
  const OnboardingAutopayToggleExperimentVariant = useExperimentVariant(OnboardingAutopayToggleExperiment)

  const isOnboardingAutopayToggleEnabled =
    OnboardingAutopayToggleExperimentVariant === OnboardingAutopayToggleExperiment.variantValues.Treatment

  return {
    isOnboardingAutopayToggleEnabled,
  }
}
