import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { OnboardingStep, OnboardingStepDetails, OnboardingStepStatus, OnboardingStepsVariant } from '@genoa/middle-end'
import { RESUME_ONBOARDING_STEPS } from '@genoa/screen-content'
import { setResumeStepAction } from '@genoa/state-management'

import { useIsEmbed } from '../../../hooks'
import { useOnboardingStatusState, useUserAccountState } from '../../../modules/flex2/use-state'
import { useAnalytics } from '../../../providers'
import * as Routes from '../../../routing/constants'
import { useNavigateToOnboardingPropertySelection } from '../../navigation'
import { useSecureLineOfCredit } from '../../secure-line-of-credit'
import { useReduxAction } from '../../use-redux-action'

type OnboardingStepProps = {
  name: string
  goToStep: () => void
  overrideStatus?: () => boolean
  skipDisplay?: boolean // hide from the UI
  dependencies?: OnboardingStep[]
  nextStep?: () => void
}

export type OnboardingStepMapping = OnboardingStepDetails & {
  name: string
  skipDisplay?: boolean
  isCurrentStep?: boolean
  missingDependencies: OnboardingStep[]
}

const forceRestartOverride = (step: any) => {
  const newStep = { ...step }
  newStep.status = OnboardingStepStatus.NOT_STARTED
  return newStep
}

export const useOnboardingStatus = (forceRestartSteps?: boolean) => {
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const [onboardingSteps, setOnboardingSteps] = useState<OnboardingStepMapping[]>([])
  const userAccount = useUserAccountState()
  const { isFlagEnabledForCreditBuilder } = useSecureLineOfCredit()
  const navigateToPropertySelection = useNavigateToOnboardingPropertySelection()
  const isEmbed = useIsEmbed()

  const setResumeStep = useReduxAction(setResumeStepAction)

  const onboardingStatus = useOnboardingStatusState()
  const stepToResume = onboardingStatus.resume_step

  const BASE_ONBOARDING_STEPS_MAPPING: Partial<Record<OnboardingStep, OnboardingStepProps>> = {
    [OnboardingStep.ACCOUNT_CREATION]: {
      name: RESUME_ONBOARDING_STEPS.ACCOUNT_CREATION,
      goToStep: () => {
        navigate(Routes.Onboarding.CREATE_ACCOUNT)
      },
      overrideStatus: () => {
        return userAccount.initialized
      },
    },
    [OnboardingStep.PROPERTY_SELECTION]: {
      name: RESUME_ONBOARDING_STEPS.PROPERTY_SELECTION,
      goToStep: navigateToPropertySelection,
      dependencies: [OnboardingStep.RENT_ESTIMATION],
    },
    [OnboardingStep.RENT_ESTIMATION]: {
      skipDisplay: true,
      name: 'Monthly rent',
      goToStep: () => {
        navigate(Routes.Onboarding.RENT_AMOUNT)
      },
      nextStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
    },
    [OnboardingStep.PAYMENT_METHOD_SETUP]: {
      skipDisplay: true,
      name: 'Payment method',
      goToStep: () => {
        navigate(Routes.Onboarding.CARD_STAGE_SELECTION)
      },
      nextStep: () => {
        navigate(Routes.Onboarding.OFFER_DETAILS)
      },
    },
    [OnboardingStep.CREDIT_ELIGIBILITY_CHECK]: {
      name: RESUME_ONBOARDING_STEPS.CREDIT_ELIGIBILITY_CHECK,
      goToStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
    },
    [OnboardingStep.OFFER_SETUP]: {
      name: 'Payment schedule',
      goToStep: () => {
        navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
      },
    },
    [OnboardingStep.REPAYMENT_SCHEDULING]: {
      name: RESUME_ONBOARDING_STEPS.REPAYMENT_SCHEDULING,
      goToStep: () => {
        navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
      },
      nextStep: () => {
        navigate(Routes.Onboarding.CARD_STAGE_SELECTION)
      },
      dependencies: [OnboardingStep.PAYMENT_METHOD_SETUP],
    },
    [OnboardingStep.OFFER_ACCEPT]: {
      name: RESUME_ONBOARDING_STEPS.OFFER_ACCEPT,
      goToStep: () => {
        navigate(Routes.Onboarding.OFFER_DETAILS)
      },
    },
  }

  const SLC_FA_ONBOARDING_STEPS_MAPPING: Partial<Record<OnboardingStep, OnboardingStepProps>> = {
    ...BASE_ONBOARDING_STEPS_MAPPING,
    [OnboardingStep.RENT_ESTIMATION]: {
      skipDisplay: true,
      name: 'Monthly rent',
      goToStep: () => {
        navigate(Routes.Onboarding.RENT_AMOUNT)
      },
      nextStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
    },
    [OnboardingStep.CREDIT_ELIGIBILITY_CHECK]: {
      name: 'Soft credit check',
      goToStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
      dependencies: [OnboardingStep.CONFIRM_APPROVAL],
    },
    [OnboardingStep.CONFIRM_APPROVAL]: {
      skipDisplay: true,
      name: 'Approved confirmation',
      goToStep: () => {
        navigate(Routes.Onboarding.HOW_FLEX_WORKS)
      },
    },
    [OnboardingStep.RENT_PROTECT_SETUP]: {
      name: 'Schedule Setup',
      goToStep: () => {
        navigate(Routes.Onboarding.HOW_TO_PAY_FLEX_BACK)
      },
      dependencies: [OnboardingStep.REPAYMENT_SCHEDULING],
    },
    [OnboardingStep.REPAYMENT_SCHEDULING]: {
      skipDisplay: true,
      name: 'Repayment scheduling',
      goToStep: () => {
        navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
      },
    },
  }

  const SLC_ONBOARDING_STEPS_MAPPING: Partial<Record<OnboardingStep, OnboardingStepProps>> = {
    ...BASE_ONBOARDING_STEPS_MAPPING,
    [OnboardingStep.RENT_ESTIMATION]: {
      skipDisplay: true,
      name: 'Monthly rent',
      goToStep: () => {
        navigate(Routes.Onboarding.RENT_AMOUNT)
      },
      nextStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
    },
    [OnboardingStep.CREDIT_ELIGIBILITY_CHECK]: {
      name: 'Soft credit check',
      goToStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
      dependencies: [OnboardingStep.CONFIRM_APPROVAL],
    },
    [OnboardingStep.CONFIRM_APPROVAL]: {
      skipDisplay: true,
      name: 'Approved confirmation',
      goToStep: () => {
        navigate(Routes.Onboarding.APPROVED)
      },
    },
    [OnboardingStep.PAYMENT_METHOD_SETUP]: {
      skipDisplay: true,
      name: 'Payment method',
      goToStep: () => {
        navigate(Routes.Onboarding.CARD_STAGE_SELECTION)
      },
    },
    [OnboardingStep.REPAYMENT_SCHEDULING]: {
      name: 'Payment details',
      goToStep: () => {
        navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
      },
      dependencies: [OnboardingStep.PAYMENT_METHOD_SETUP],
    },
  }

  const CREDIT_BUILDER_ONBOARDING_STEPS_MAPPING: Partial<Record<OnboardingStep, OnboardingStepProps>> = {
    ...BASE_ONBOARDING_STEPS_MAPPING,
    [OnboardingStep.ACCOUNT_CREATION]: {
      name: 'Account creation',
      goToStep: () => {
        navigate(Routes.Onboarding.CREATE_ACCOUNT)
      },
      overrideStatus: () => {
        return userAccount.initialized
      },
      dependencies: [OnboardingStep.VALUE_PROP],
    },
    [OnboardingStep.VALUE_PROP]: {
      name: 'Value prop',
      skipDisplay: true,
      goToStep: () => {
        navigate(Routes.Onboarding.CREDIT_BUILDER_VALUE_PROP)
      },
    },
    [OnboardingStep.RENT_ESTIMATION]: {
      skipDisplay: true,
      name: 'Monthly rent',
      goToStep: () => {
        navigate(Routes.Onboarding.RENT_AMOUNT)
      },
    },
    [OnboardingStep.CREDIT_ELIGIBILITY_CHECK]: {
      name: 'Soft credit check',
      goToStep: () => {
        navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
      },
    },
    [OnboardingStep.REPAYMENT_SCHEDULING]: {
      name: 'Payment Details',
      goToStep: () => {
        navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
      },
    },
    [OnboardingStep.OFFER_ACCEPT]: {
      name: 'Confirmation',
      goToStep: () => {
        navigate(Routes.Onboarding.OFFER_DETAILS)
      },
    },
  }

  const EMBED_ONBOARDING_STEPS_MAPPING: Partial<Record<OnboardingStep, OnboardingStepProps>> = {
    ...BASE_ONBOARDING_STEPS_MAPPING,
    [OnboardingStep.PROPERTY_SELECTION]: {
      name: 'Property details',
      goToStep: () => {
        navigate(Routes.Onboarding.DIRECT_INTEGRATION_CONFIRMATION)
      },
      dependencies: [OnboardingStep.RENT_ESTIMATION],
    },
  }

  const ONBOARDING_STEPS_MAPPING = useMemo(() => {
    if (isEmbed) {
      return EMBED_ONBOARDING_STEPS_MAPPING
    }

    if (!onboardingStatus?.initialized) {
      return BASE_ONBOARDING_STEPS_MAPPING
    }

    switch (onboardingStatus.steps_variant) {
      case OnboardingStepsVariant.TREATMENT:
        return SLC_FA_ONBOARDING_STEPS_MAPPING
      case OnboardingStepsVariant.CREDIT_BUILDER:
        return CREDIT_BUILDER_ONBOARDING_STEPS_MAPPING
      case OnboardingStepsVariant.SLC:
        return SLC_ONBOARDING_STEPS_MAPPING
      case OnboardingStepsVariant.CONTROL:
      default:
        return BASE_ONBOARDING_STEPS_MAPPING
    }
  }, [isEmbed, onboardingStatus, isFlagEnabledForCreditBuilder])

  const resolveNextStep = useCallback(
    (onboardingStep: OnboardingStep, fallback: () => void) => {
      const nextStep = ONBOARDING_STEPS_MAPPING[onboardingStep]?.nextStep
      if (nextStep) {
        return nextStep()
      }

      fallback?.()
    },
    [ONBOARDING_STEPS_MAPPING]
  )

  const handleResumeOnboarding = useCallback(
    (fallback: () => void) => {
      if (!onboardingSteps || onboardingSteps?.length === 0) {
        fallback?.()
      }

      const stepToResumeFrom = onboardingSteps.find((step) => step.isCurrentStep)
      if (stepToResumeFrom) {
        const mapping = ONBOARDING_STEPS_MAPPING[stepToResumeFrom.step]
        if (mapping) {
          if (stepToResumeFrom.missingDependencies?.length) {
            const nextMissingDependency = stepToResumeFrom.missingDependencies[0]
            const nextMissingDependencyConfig = ONBOARDING_STEPS_MAPPING[nextMissingDependency]

            setResumeStep(nextMissingDependency)
            analytics.setUserProperty(Analytics.UserProperties.RESUME_TO_STEP, nextMissingDependency)
            analytics.logEvent(Analytics.Events.RESUME_ONBOARDING_TO_STEP, {
              step: stepToResumeFrom.step,
              dependency: nextMissingDependency,
            })

            nextMissingDependencyConfig?.goToStep()
            return
          }

          setResumeStep(stepToResumeFrom.step)
          analytics.setUserProperty(Analytics.UserProperties.RESUME_TO_STEP, stepToResumeFrom.step)
          analytics.logEvent(Analytics.Events.RESUME_ONBOARDING_TO_STEP, { step: stepToResumeFrom.step })
          mapping.goToStep()
          return
        }
      }

      fallback?.()
    },
    [onboardingSteps, ONBOARDING_STEPS_MAPPING]
  )

  useEffect(() => {
    if (!onboardingStatus.initialized || onboardingStatus.steps?.length <= 0) {
      return
    }

    const initialSteps = [...onboardingStatus.steps].sort((a, b) => a.order - b.order)
    const steps = forceRestartSteps ? initialSteps.map(forceRestartOverride) : initialSteps
    const currentStep = getCurrentStep(steps)
    const completedSteps = getCompletedSteps(steps)
    const stepsMapping = mapSteps(steps, currentStep, completedSteps)

    setOnboardingSteps(stepsMapping)
  }, [userAccount, onboardingStatus, forceRestartSteps, ONBOARDING_STEPS_MAPPING])

  /*
  Find the first step that has not been started
  */
  const getCurrentStep = (steps: OnboardingStepDetails[]): OnboardingStepDetails | undefined => {
    return steps.find((step) => step.status === OnboardingStepStatus.NOT_STARTED)
  }

  /*
  Get a list of completed step identifiers
  */
  const getCompletedSteps = (steps: OnboardingStepDetails[]): OnboardingStep[] => {
    return steps.filter((step) => step.status === OnboardingStepStatus.COMPLETE).map((step) => step.step)
  }

  /*
  Map each step to an object with additional properties related to navigation and dependencies
  */
  const mapSteps = (
    steps: OnboardingStepDetails[],
    currentStep: OnboardingStepDetails | undefined,
    completedSteps: OnboardingStep[]
  ): OnboardingStepMapping[] => {
    return steps.map<OnboardingStepMapping>((step) => {
      const mapping = ONBOARDING_STEPS_MAPPING[step.step as keyof typeof ONBOARDING_STEPS_MAPPING]
      const baselineMapping = createBaselineMapping(step, mapping, currentStep)

      // only check for dependencies if the step is not the current step
      return mapping?.dependencies && !baselineMapping.isCurrentStep
        ? updateMappingForDependencies(baselineMapping, mapping.dependencies, completedSteps, currentStep)
        : baselineMapping
    })
  }

  const createBaselineMapping = (
    step: OnboardingStepDetails,
    mapping: OnboardingStepProps | undefined,
    currentStep: OnboardingStepDetails | undefined
  ): OnboardingStepMapping => {
    return {
      ...step,
      status: step.status,
      name: mapping?.name!,
      skipDisplay: mapping?.skipDisplay,
      isCurrentStep: step.step === currentStep?.step,
      missingDependencies: [],
    }
  }

  /*
  Update the mapping to include missing dependencies if any and to decide if step should bet set to current step
  */
  const updateMappingForDependencies = (
    baselineMapping: OnboardingStepMapping,
    dependencies: OnboardingStep[],
    completedSteps: OnboardingStep[],
    currentStep: OnboardingStepDetails | undefined
  ): OnboardingStepMapping => {
    const missingDependencies = dependencies.filter((dependency) => !completedSteps.includes(dependency))
    // if children dependency is missing and is the current step then the parent becomes the parent step
    const isDependencyCurrentStep = missingDependencies.includes(currentStep?.step!)

    return missingDependencies.length
      ? {
          ...baselineMapping,
          status: OnboardingStepStatus.NOT_STARTED,
          isCurrentStep: baselineMapping.isCurrentStep || isDependencyCurrentStep,
          missingDependencies,
        }
      : baselineMapping
  }
  return {
    handleResumeOnboarding,
    onboardingSteps,
    stepToResume,
    resolveNextStep,
  }
}
