import React from 'react'
import { PREFILL_ADDRESS } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { LinkText, SmallText } from '../../../components'

interface PrefillsConfirmBannerProps {
  readonly onReset?: () => void
}

const StartOverLinkText = (props: Required<PrefillsConfirmBannerProps>) => {
  return (
    <LinkText fontSize="16px" textAlign="left" onClick={props.onReset}>
      <b>{PREFILL_ADDRESS.START_OVER}</b>
    </LinkText>
  )
}

export const PrefillConfirmBanner = (props: PrefillsConfirmBannerProps) => (
  <PrefillsBanner flexShrink={0} direction="column" bg="cloud">
    <SmallText fontSize="16px" textAlign="left" alignSelf="stretch" data-testid="PrefillConfirmBannerBody">
      {PREFILL_ADDRESS.BODY}
    </SmallText>
    {props.onReset && (
      <Box>
        {PREFILL_ADDRESS.NOT_RIGHT} <StartOverLinkText onReset={props.onReset} />
      </Box>
    )}
  </PrefillsBanner>
)

const PrefillsBanner = styled(Flex)`
  padding: 16px;
  gap: 12px;
  border-radius: 16px;
  bg: cloud;
`
