import { useCallback, useEffect, useState } from 'react'
import { FlexLinks } from '@genoa/domain'
import { LLMChatbotExperiment } from '@genoa/experiments'

import { useAuthState } from '../../contexts'
import { useEmbed, useIsInEmbedFrame } from '../../hooks'
import { useAccount } from '../../hooks/use-account'
import { useExperimentVariant } from '../../hooks/use-experiment-variant'
import { useGenerateAdaTokenQuery } from '../../modules/flexApi'
import { Config, useLogger } from '../../providers'

const Linking = {
  openURL: (url: string, sameTab: boolean = false) => {
    if (sameTab) {
      return window.open(url)
    }

    const win = window.open(url, '_blank')
    win && win.focus()
  },
}

interface Params {
  [key: string]: string | number | boolean | null | undefined
}

const encodeParams = (params: Params) => {
  const paramStrings = []

  for (const key in params) {
    const value = params[key]
    if (value !== null && value !== undefined) {
      const encodedKey = encodeURIComponent(key)
      const encodedValue = typeof value === 'boolean' ? String(value) : encodeURIComponent(String(value))
      paramStrings.push(`${encodedKey}=${encodedValue}`)
    }
  }

  return paramStrings.join('&')
}

export const useFlexLinks = () => {
  const open = useCallback((link: FlexLinks, urlParams?: Params) => {
    let finalLink: string = link

    if (link === FlexLinks.helpHome && Config.ENVIRONMENT !== 'production') {
      finalLink = FlexLinks.helpHomeTest
    }

    if (urlParams) {
      const queryString = encodeParams(urlParams)
      if (queryString) {
        finalLink = `${finalLink}?${queryString}`
      }
    }

    Linking.openURL(finalLink)
  }, [])

  return { open }
}

export const useHelpLinks = () => {
  const flexLinks = useFlexLinks()

  const { isEmbed, origin } = useEmbed()
  const isInEmbedFrame = useIsInEmbedFrame()
  const { isAnonymous, uid } = useAuthState()
  const logger = useLogger('useChatbotLink')
  const LLMChatbotExperimentVariant = useExperimentVariant(LLMChatbotExperiment)
  const isLLMChatbotEnabled = LLMChatbotExperimentVariant === LLMChatbotExperiment.variantValues.Treatment
  const { isActive } = useAccount()
  const { data, error } = useGenerateAdaTokenQuery({ customerId: uid! }, { skip: !uid || isAnonymous || !isActive })
  const adaToken = data?.data.token
  const [chatbotParams, setChatbotParams] = useState({
    chatbot: isLLMChatbotEnabled ? 'treatment' : 'control',
    is_embed: isEmbed,
    openChatBot: true,
    uid: uid!,
  })

  useEffect(() => {
    if (error) {
      if (error instanceof Error) {
        logger.error(error.message)
      }
      logger.warn('Failed authenticated Ada session - using logged out chatbot experience')
    } else if (adaToken) {
      setChatbotParams((prev) => ({ ...prev, ada_token: adaToken }))
    }
  }, [adaToken, error])

  const open = (url: FlexLinks) => {
    const helpLinkParams: Params = { ...chatbotParams }

    if (isEmbed) {
      if (isInEmbedFrame && origin) {
        helpLinkParams.utm_referrer = origin
      }
      helpLinkParams.utm_medium = 'partner_embed'
    }

    flexLinks.open(url, helpLinkParams)
  }

  return { open }
}
