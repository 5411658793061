import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CONFIRM_ACCOUNT } from '@genoa/screen-content'

import { CreateAccountContainer } from './CreateAccountContainer'

type ConfirmAccountContainerProps = {
  onNext: () => void
}

export const ConfirmAccountContainer = (props: ConfirmAccountContainerProps) => (
  <CreateAccountContainer
    headerText={CONFIRM_ACCOUNT.HEADER}
    subheaderText={CONFIRM_ACCOUNT.SUBHEADER}
    submitButtonText={CONFIRM_ACCOUNT.CTA}
    analyticsScreenName={Analytics.Screens.NO_MATCH_CONFIRM_ACCOUNT}
    firstNameEditEvent={Analytics.Events.NO_MATCH_CONFIRM_ACCOUNT_FIRST_NAME_EDIT}
    lastNameEditEvent={Analytics.Events.NO_MATCH_CONFIRM_ACCOUNT_LAST_NAME_EDIT}
    emailEditEvent={Analytics.Events.NO_MATCH_CONFIRM_ACCOUNT_EMAIL_EDIT}
    termsOfServiceClickedEvent={Analytics.Events.NO_MATCH_CONFIRM_TERMS_OF_SERVICE_CLICKED}
    privacyPolicyClickedEvent={Analytics.Events.NO_MATCH_CONFIRM_PRIVACY_POLICY_CLICKED}
    privacyNoticeClickedEvent={Analytics.Events.NO_MATCH_CONFIRM_PRIVACY_NOTICE_CLICKED}
    errorOnCTAEvent={Analytics.Events.NO_MATCH_CONFIRM_ERROR_ON_CTA}
    accountCreationClickedEvent={Analytics.Events.NO_MATCH_CONFIRM_ACCOUNT_CTA_CLICKED}
    emailAlreadyInUseEvent={Analytics.Events.NO_MATCH_CONFIRM_EMAIL_ALREADY_IN_USE}
    onNext={props.onNext}
  />
)
