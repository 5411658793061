import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { VerificationStatus } from '@genoa/domain'
import { OfferStates, OnboardingStep, OnboardingStepStatus, useRestartOnboarding } from '@genoa/middle-end'
import { CONFIRM_ACCOUNT } from '@genoa/screen-content'
import { EvaluationInstruction, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../../../contexts'
import {
  useHandleNavigationForCreditBuilderValueProp,
  useIsEmbed,
  useOnboardingInAppIncomeVerificationExperiment,
  useShouldRestartEmbedOnboarding,
} from '../../../../hooks'
import { useHandleGetOnboardingStatus } from '../../../../hooks/flex2/onboarding-status'
import { useOfferState, useOnboardingStatusState } from '../../../../modules/flex2/use-state'
import { useContinueEvaluationMutation } from '../../../../modules/flexApi'
import { useAnalytics, useLogger } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { CreateAccountContainer } from '../account-creation/CreateAccountContainer'
import { ResumeOnboardingToStepContainer } from './ResumeOnboardingToStepContainer'

export const OnboardingResumeOnboardingContainer = () => {
  const { navigateNext } = useHandleNavigationForCreditBuilderValueProp({ source: 'create' })
  const navigate = useNavigate()
  const onboardingStatusState = useOnboardingStatusState()
  const offerState = useOfferState()
  const goToFallback = () => navigate(Routes.Onboarding.ADDRESS)
  const { uid } = useAuthState()
  const { handleGetOnboardingStatus, loadingOnboardingStatus } = useHandleGetOnboardingStatus()
  const isEmbed = useIsEmbed()
  const analytics = useAnalytics()
  const loggerV2 = useLogger('OnboardingResumeOnboardingContainer')
  const [_, restartOnboarding] = useRestartOnboarding()
  const shouldRestartEmbedOnboarding = useShouldRestartEmbedOnboarding()

  const { isOnboardingInAppIncomeVerificationEnabled } = useOnboardingInAppIncomeVerificationExperiment()

  const [continueEvaluation, { error: continueEvaluationError }] = useContinueEvaluationMutation()

  useEffect(() => {
    if (uid) {
      if (isEmbed && shouldRestartEmbedOnboarding) {
        restartOnboarding({ customerPublicId: uid })
      }
      handleGetOnboardingStatus(uid, 'treatment')
    }
  }, [uid, isEmbed, shouldRestartEmbedOnboarding])

  const handleIncomeVerification = async () => {
    const res = await continueEvaluation({ customerId: uid! }).unwrap()

    switch (res?.data.income_verification_status) {
      case VerificationStatus.COMPLETED:
      case VerificationStatus.MANUAL_REVIEW:
      case VerificationStatus.SUBMITTED:
        return navigate(Routes.Onboarding.INCOME_VERIFICATION_WAITING_OFFER)
      default:
        // Block new IV users if experiment is disabled
        if (!isOnboardingInAppIncomeVerificationEnabled) {
          return navigate(Routes.Onboarding.UPSELL_ELIGIBILITY, { replace: true })
        }
        if (res.data.instruction === EvaluationInstruction.NeedsFurtherVerification) {
          return navigate(Routes.Onboarding.VERIFY_YOUR_INCOME)
        }
        // All other VerificationStatus states need to submit or re-submit IV
        return navigate(Routes.Onboarding.WHATS_YOUR_INCOME)
    }
  }

  useQueryError(continueEvaluationError, {
    onAllErrors() {
      loggerV2.error('Error polling Continue Evaluation')
      analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_FAILURE)
    },
  })

  const resolverResumeState = useCallback(
    (rentEstimationCompleted: boolean) => {
      const offer = offerState?.offer
      if (!offer) {
        return
      }
      if (
        rentEstimationCompleted &&
        (offer.offer_state === OfferStates.EXPIRED || offer.withdrawal_reason === 'Expiration')
      ) {
        return OfferStates.EXPIRED.toString()
      }
    },
    [offerState?.offer]
  )

  if (!onboardingStatusState.loaded || loadingOnboardingStatus) {
    return <FullScreenSpinnerLoading />
  }

  const accountCreationCompleted = onboardingStatusState.steps.find(
    (step) => step.step === OnboardingStep.ACCOUNT_CREATION && step.status === OnboardingStepStatus.COMPLETE
  )
  const rentEstimationCompleted = onboardingStatusState.steps.find(
    (step) => step.step === OnboardingStep.RENT_ESTIMATION && step.status === OnboardingStepStatus.COMPLETE
  )

  if (accountCreationCompleted) {
    return (
      <ResumeOnboardingToStepContainer
        analyticsScreenName={Analytics.Screens.SIGNUP_RESUME_ONBOARDING}
        goToFallback={goToFallback}
        resumeState={resolverResumeState(!!rentEstimationCompleted)}
        handleIncomeVerification={handleIncomeVerification}
      />
    )
  }

  return (
    <CreateAccountContainer
      headerText={CONFIRM_ACCOUNT.HEADER}
      subheaderText={CONFIRM_ACCOUNT.SUBHEADER}
      submitButtonText={CONFIRM_ACCOUNT.CTA}
      analyticsScreenName={Analytics.Screens.CONFIRM_ACCOUNT}
      firstNameEditEvent={Analytics.Events.CONFIRM_ACCOUNT_FIRST_NAME_EDIT}
      lastNameEditEvent={Analytics.Events.CONFIRM_ACCOUNT_LAST_NAME_EDIT}
      emailEditEvent={Analytics.Events.CONFIRM_ACCOUNT_EMAIL_EDIT}
      termsOfServiceClickedEvent={Analytics.Events.CONFIRM_TERMS_OF_SERVICE_CLICKED}
      privacyPolicyClickedEvent={Analytics.Events.CONFIRM_PRIVACY_POLICY_CLICKED}
      privacyNoticeClickedEvent={Analytics.Events.CONFIRM_PRIVACY_NOTICE_CLICKED}
      errorOnCTAEvent={Analytics.Events.CONFIRM_ERROR_ON_CTA}
      accountCreationClickedEvent={Analytics.Events.CONFIRM_ACCOUNT_CREATION_CTA_CLICKED}
      emailAlreadyInUseEvent={Analytics.Events.CONFIRM_EMAIL_ALREADY_IN_USE}
      onNext={navigateNext}
    />
  )
}
