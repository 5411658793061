import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { FlexAnywhereGenericPortal } from '@genoa/domain'
import { P2P_WAITLIST_CONTENT } from '@genoa/screen-content'
import { setBillerGenericPortalAction } from '@genoa/state-management'
import { Box, Stack } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useReduxAction } from '../../../../hooks'
import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { FlexAnywhereLoadCard, Megaphone } from '../../../assets'
import { Field, Gap, Headline2, PrimaryButton, SmallText } from '../../../components'
import { RadioGroup } from '../../../components/Form/RadioGroup'
import { BasePageLayout } from '../../../layouts'

const {
  BODY,
  PAYMENT_CHANGE_FIELD_NAME,
  PAYMENT_CHANGE_FIELD_LABEL,
  PAYMENT_CHANGE_OPTIONS,
  CTA,
  ERROR,
  PAY_FIELD_LABEL,
  PAY_FIELD_NAME,
  PAY_OPTIONS,
  TITLE,
  SUCCESS_BODY,
  SUCCESS_HEADING,
  LANDLORD_PROPERTY_MANAGER_FIELD_LABEL,
  LANDLORD_PROPERTY_MANAGER_FIELD_NAME,
  PLACEHOLDER,
  FLEX_ANYWHERE_TITLE,
  FLEX_ANYWHERE_BODY,
  FLEX_ANYWHERE_CTA,
} = P2P_WAITLIST_CONTENT

const schema = yup.object().shape({
  [PAY_FIELD_NAME]: yup.string().required(),
  [PAYMENT_CHANGE_FIELD_NAME]: yup.string().required(),
  [LANDLORD_PROPERTY_MANAGER_FIELD_NAME]: yup.string().required(),
})

const SuccessState = ({
  showFlexAnywhereScreen,
  onClickLearnMore,
}: {
  showFlexAnywhereScreen: boolean
  onClickLearnMore: () => void
}) => {
  if (showFlexAnywhereScreen) {
    return (
      <Stack alignItems="center" mt="8">
        <FlexAnywhereLoadCard />
        <Headline2 textAlign="center">{FLEX_ANYWHERE_TITLE}</Headline2>
        <SmallText textAlign="center">{FLEX_ANYWHERE_BODY}</SmallText>
        <Gap size="spacing_200" />
        <PrimaryButton onClick={onClickLearnMore} testID="LearnMoreButton">
          {FLEX_ANYWHERE_CTA}
        </PrimaryButton>
      </Stack>
    )
  } else
    return (
      <Stack alignItems="center" mt="8">
        <Megaphone />
        <Headline2 textAlign="center">{SUCCESS_HEADING}</Headline2>
        <SmallText textAlign="center">{SUCCESS_BODY}</SmallText>
      </Stack>
    )
}

export function P2PWaitlist() {
  const { colors } = useTheme()
  const useFormProps = useForm({ resolver: yupResolver(schema) })

  const {
    formState: { isValid, isSubmitted, isSubmitSuccessful, isSubmitting },
    handleSubmit,
    control,
  } = useFormProps
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const onSubmit = handleSubmit((data) => {
    analytics.logEvent(Analytics.Events.P2P_WAITLIST_CTA_CLICKED, data)
  })

  const [showFlexAnywhereScreen, setShowFlexAnywhereScreen] = useState(false)

  const setBillerGenericPortal = useReduxAction(setBillerGenericPortalAction)

  const handleOptionSelect = (value: string) => {
    if (value === 'rent_portal_or_property_website') {
      // We have to set a portal otherwise an error occurs on the next screen when user continues to FA flow
      setBillerGenericPortal(FlexAnywhereGenericPortal)
      setShowFlexAnywhereScreen(true)
    }
  }

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_P2P_WAITLIST}>
      <Stack spacing={5} alignItems="center" mt="spacing_50">
        {isSubmitSuccessful ? (
          <SuccessState
            showFlexAnywhereScreen={showFlexAnywhereScreen}
            onClickLearnMore={() => navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_YOU_PAY)}
          />
        ) : (
          <>
            <Megaphone />
            <Headline2 textAlign="center">{TITLE}</Headline2>
            <SmallText textAlign="center">{BODY}</SmallText>
            <Box w="full" borderRadius="spacing_50" p="spacing_25">
              <FormProvider {...useFormProps}>
                <form onSubmit={onSubmit}>
                  <Stack spacing={8}>
                    <RadioGroup
                      name={PAY_FIELD_NAME}
                      options={PAY_OPTIONS}
                      title={PAY_FIELD_LABEL}
                      allowCustomInput
                      customInputConfig={{ value: 'other', maxLength: 500, placeholder: PLACEHOLDER }}
                      onOptionSelect={handleOptionSelect}
                    />
                    <RadioGroup
                      name={PAYMENT_CHANGE_FIELD_NAME}
                      title={PAYMENT_CHANGE_FIELD_LABEL}
                      options={PAYMENT_CHANGE_OPTIONS}
                    />
                    <Box mb="spacing_0">
                      <Field
                        label={<SmallText fontWeight="bold">{LANDLORD_PROPERTY_MANAGER_FIELD_LABEL}</SmallText>}
                        name={LANDLORD_PROPERTY_MANAGER_FIELD_NAME}
                        control={control}
                        maxLength={200}
                      />
                    </Box>
                    {!isValid && isSubmitted && (
                      <SmallText textAlign="center" color={colors.red}>
                        {ERROR}
                      </SmallText>
                    )}
                    <PrimaryButton processing={isSubmitting} type="submit">
                      {CTA}
                    </PrimaryButton>
                  </Stack>
                </form>
              </FormProvider>
            </Box>
          </>
        )}
      </Stack>
    </BasePageLayout>
  )
}
