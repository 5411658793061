import React, { ReactNode } from 'react'
import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, INCOME_VERIFICATION_BANK_LINK as content } from '@genoa/screen-content'
import { toDollars } from '@genoa/utils'
import { Box, Flex, ListItem, UnorderedList } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ExtraSmallText, Gap, Headline1, PrimaryButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface VerifyYourIncomeProps {
  analyticsScreenName: Analytics.Screens
  annualIncomeDollars: number
  onClickContinue: () => void
  isLoading: boolean
  onClickUploadPaystubs: () => void
  isReady: boolean
}

export const VerifyYourIncome = (props: VerifyYourIncomeProps) => {
  const mainBodyContent = deepReplaceContent(content.BODY, {
    incomeAmount: toDollars(props.annualIncomeDollars * 100),
  })
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Flex direction="column">
        <Gap size={'spacing_200'} />

        <Headline1>{content.HEADER}</Headline1>

        <Gap size={'spacing_100'} />

        <SmallText>{mainBodyContent}</SmallText>

        <Gap size={'spacing_200'} />

        <BankAccountRequirementsWrapper>
          <BankAccountRequirementsHeader fontWeight={'bold'}>
            {content.REQUIREMENTS_HEADER}
          </BankAccountRequirementsHeader>
          <Gap size={'spacing_50'} />
          <UnorderedList data-testid={'BankAccountRequirementsList'}>
            {content.REQUIREMENTS_BULLETS.map((item, index) => (
              <BankAccountRequirementsListItem key={`Requirements-item-${index}`}>
                {item}
              </BankAccountRequirementsListItem>
            ))}
          </UnorderedList>
        </BankAccountRequirementsWrapper>

        <Gap size={'spacing_400'} />
        <UploadPaystubsWrapper>
          <SmallText>{content.PAYSTUB}</SmallText>
          <UploadPaystubsLink
            fontWeight={'bold'}
            onClick={props.onClickUploadPaystubs}
            data-testid={'UploadPaystubsLink'}
          >
            {content.PAYSTUB_LINK}
          </UploadPaystubsLink>
        </UploadPaystubsWrapper>

        <Gap size={'spacing_100'} />

        <PrimaryButton
          processing={props.isLoading}
          onClick={props.onClickContinue}
          testID="VerifyYourIncomeContinueButton"
          disabled={!props.isReady}
        >
          {content.CTA}
        </PrimaryButton>
      </Flex>
    </BasePageLayout>
  )
}

const BankAccountRequirementsWrapper = styled(Box)`
  color: ${(props) => props.theme.colors.dusk};
`

const BankAccountRequirementsHeader = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

const BankAccountRequirementsListItem = styled(ListItem)`
  font-size: ${(props) => props.theme.fontSizes.xxs};
`

const UploadPaystubsWrapper = styled(SmallText)``

const UploadPaystubsLink = styled(SmallText)`
  color: ${(props) => props.theme.colors.jewelPurple};
  cursor: pointer;
`
