import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import styled from '@emotion/styled'

import { useModal } from '../../contexts'
import { useAnalytics, useHelpLinks } from '../../providers'
import * as Routes from '../../routing/constants'
import { SmallText } from '../../views/components'

export const useShowNoMatchError = () => {
  const modal = useModal()
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const helpLinks = useHelpLinks()

  const handleContactSupport = useCallback(() => {
    analytics.logEvent(Analytics.Events.CONTACT_SUPPORT_CTA_CLICKED, {
      source: 'no-match-confirmation',
    })
    helpLinks.open(FlexLinks.helpHome)
  }, [helpLinks.open])

  const renderNoMatchError = useCallback(() => {
    modal.show({
      title: 'Couldn’t connect to property',
      cta: 'Review account details',
      onCTAclicked: () => navigate(Routes.Onboarding.CONFIRM_ACCOUNT),
      hasCloseButton: true,
      render: () => (
        <SmallText>
          In order to connect directly to your property, your name, email, and phone number should match what your
          property has on file. Please review your account information, and if the problem persists,{' '}
          <ContactSupportLink onClick={handleContactSupport} fontWeight="bold">
            reach out to our support team
          </ContactSupportLink>
        </SmallText>
      ),
    })
  }, [helpLinks.open])

  return {
    renderNoMatchError,
  }
}

const ContactSupportLink = styled(SmallText)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.jewelPurple};
`
