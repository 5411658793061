import { Analytics } from '@genoa/analytics'
import { CONTACT_SUPPORT } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroSpill as SupportImage } from '../../assets'
import { Headline1, PrimaryButton, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type ContactSupportProps = {
  onMessageSupport: () => unknown
  analyticsScreenName: Analytics.Screens
}

export const ContactSupport = (props: ContactSupportProps) => (
  <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
    <Box minH={'60px'} />
    <Flex direction="column" align="center">
      <Container align="center" justify="center">
        <SupportImage />
      </Container>

      <Box minH={'24px'} />

      <Headline1 textAlign="center">{CONTACT_SUPPORT.HEADER}</Headline1>

      <Box minH={'24px'} />

      <Text textAlign="center" color={'dusk'}>
        {CONTACT_SUPPORT.BODY_1}
      </Text>

      <Box minH={'24px'} />

      <Text textAlign="center" color={'dusk'} data-testid="ContactSupportBody2">
        {CONTACT_SUPPORT.BODY_2}
      </Text>

      <Box minH={'30px'} />

      <PrimaryButton testID={'ContactSupportMessageSupport'} onClick={props.onMessageSupport}>
        {CONTACT_SUPPORT.CTA}
      </PrimaryButton>
    </Flex>
  </BasePageLayout>
)

const Container = styled(Flex)`
  height: 100%;
`
