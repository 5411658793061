import React from 'react'
import { Analytics } from '@genoa/analytics'
import { INCOME_VERIFICATION_JUST_A_LITTLE_LONGER as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroPhone } from '../../../assets'
import { Gap, Headline1, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

export const IncomeVerificationJustALittleLonger = () => {
  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.INCOME_VERIFICATION_JUST_A_LITTLE_LONGER}>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Gap size={'spacing_200'} />

        <HeroPhone />

        <Gap size={'spacing_200'} />

        <Headline1>{content.HEADER}</Headline1>

        <Gap size={'spacing_150'} />

        <BodyText>{content.BODY_1}</BodyText>
        <Gap size={'spacing_150'} />
        <BodyText>{content.BODY_2}</BodyText>
      </Flex>
    </BasePageLayout>
  )
}

const BodyText = styled(Text)`
  color: ${(props) => props.theme.colors.dusk};
  text-align: center;
`
