import React from 'react'
import { Analytics } from '@genoa/analytics'
import { DOCUMENT_VERIFICATION } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'

import { Gap, Headline1, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'

interface DocumentVerificationProps {
  isLoading: boolean
  analyticsScreenName: Analytics.Screens
  onClickContinue: () => void
}

export const DocumentVerification = (props: DocumentVerificationProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Flex direction="column">
        <Gap size={'spacing_200'} />

        <Headline1>{DOCUMENT_VERIFICATION.HEADER}</Headline1>

        <Gap size={'spacing_200'} />

        <SmallText>
          <SmallText>{DOCUMENT_VERIFICATION.BODY}</SmallText>
        </SmallText>

        <Gap size={'spacing_100'} />

        <SmallText>
          <SmallText fontWeight={'bold'}>{DOCUMENT_VERIFICATION.DISCLAIMER}</SmallText>
        </SmallText>

        <Gap size={'spacing_200'} />

        <PrimaryButton
          processing={props.isLoading}
          onClick={props.onClickContinue}
          testID="DocumentVerificationContinueButton"
        >
          {DOCUMENT_VERIFICATION.CTA}
        </PrimaryButton>
      </Flex>
    </BasePageLayout>
  )
}
