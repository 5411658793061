import React from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { RENT_AMOUNT } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useFlexAnywhereUser } from '../../../../hooks'
import { useAmplitudeFeatureFlag } from '../../../../hooks/use-amplitude-feature-flag'
import { RentAmountState } from '../../../../modules'
import { Field, Headline1, MoneyInput, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { rentValidator } from './common'
import { RentAmountChanges } from './RentAmountChanges'

export type InitiateOfferRentAmountProps = {
  submitRentAmount: (RentAmount: RentAmountState) => unknown
  rentAmount?: RentAmountState
  isLoading: boolean
  errors: {
    propertyName?: FieldError
    amount?: FieldError
  }
  onNext: (amount: number, propertyName?: string) => unknown
  onBack?: () => unknown
  analyticsScreenName: Analytics.Screens
  rentAmountChangeModalVisible: boolean
  setRentAmountChangeModalVisible: (value: boolean) => unknown
  showConfirmText: boolean
}

const rentAmountSchema = yup.object({
  amount: rentValidator,
  propertyName: yup.string(),
})

const rentAmountSchemaFA = yup.object({
  amount: rentValidator,
  propertyName: yup.string().required('Property name is required'),
})

export const InitiateOfferRentAmount = (props: InitiateOfferRentAmountProps) => {
  const isFlexAnywhere = useFlexAnywhereUser()
  const { enabled: isPropertyNameFieldEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.PropertyNameEnabled)

  const yupSchema = isFlexAnywhere && isPropertyNameFieldEnabled ? rentAmountSchemaFA : rentAmountSchema

  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm },
  } = useForm<RentAmountState>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
  })

  const onSubmit = handleSubmit((data: RentAmountState) => {
    if (!props.isLoading && data.amount) {
      props.submitRentAmount(data)
      props.onNext(parseFloat(data.amount), data.propertyName)
    }
  })

  const FLEX_TYPE = isFlexAnywhere ? 'FLEX_ANYWHERE' : 'CLASSIC'
  const content = RENT_AMOUNT[FLEX_TYPE]

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH={'30px'} />

      <Box flex={1} px="lg">
        <Box>
          <Headline1 data-testid="RentAmountHeader">
            {props.showConfirmText ? RENT_AMOUNT.CONFIRM_HEADER : content.HEADER}
          </Headline1>

          <Box minH={'24px'} />

          <SmallTextStyled data-testid="RentAmountBody">{content.BODY}</SmallTextStyled>
        </Box>

        <Box minH={'32px'} />

        {isFlexAnywhere && isPropertyNameFieldEnabled && (
          <>
            <Field
              label={RENT_AMOUNT.PROPERTY_NAME_INPUT_LABEL}
              name="propertyName"
              control={control}
              defaultValue={props.rentAmount?.propertyName}
              error={errorsForm.propertyName || props.errors.propertyName}
              testID="PropertyNameInput"
              helperText={RENT_AMOUNT.PROPERTY_NAME_HELPER_TEXT}
            />
            <Box minH={'32px'} />
          </>
        )}

        {props.rentAmount && (
          <Field
            input={MoneyInput}
            label={content.INPUT_LABEL}
            name="amount"
            control={control}
            defaultValue={props.rentAmount.amount}
            error={errorsForm.amount || props.errors.amount}
            testID="RentAmountMonthlyRentInput"
            helperText={RENT_AMOUNT.HELPER_TEXT}
          />
        )}
        <Box minH="84px" />
        <Box>
          <PrimaryButton
            testID="RentAmountNext"
            onClick={onSubmit}
            disabled={props.isLoading}
            processing={props.isLoading}
          >
            {RENT_AMOUNT.CTA}
          </PrimaryButton>
        </Box>
      </Box>
      <Box style={{ zIndex: 2 }}>
        <RentAmountChanges
          visible={props.rentAmountChangeModalVisible}
          header={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.HEADER}
          bodyOne={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.BODY_ONE}
          bodyTwo={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.BODY_TWO}
          cta={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.CTA}
          onClose={() => props.setRentAmountChangeModalVisible(false)}
        />
      </Box>
    </BasePageLayout>
  )
}

const SmallTextStyled = styled(SmallText)`
  margin-bottom: 0;
`
