import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ExtraSmallText } from '../Typography'

export interface PillProps {
  readonly label: string
  readonly backgroundColor?: string
  readonly borderRadius?: string
  readonly textColor?: string
}

export const Pill = (props: PillProps) => {
  return (
    <PillContainer color={props.backgroundColor} borderRadius={props.borderRadius}>
      <PillText textColor={props.textColor}>{props.label}</PillText>
    </PillContainer>
  )
}

const PillContainer = styled(Box)<{ color?: string; borderRadius?: string }>`
  display: inline-flex;
  padding: ${(props) => props.theme.fixedSizes.spacing_25} ${(props) => props.theme.fixedSizes.spacing_62_5};
  border-radius: ${(props) => props.borderRadius || props.theme.radii.rounded};
  background-color: ${(props) => props.color || props.theme.colors.cloud};
`

const PillText = styled(ExtraSmallText)`
    font-size: ${(props) => props.theme.fixedSizes.spacing_62_5};
    font-weight: ${(props) => props.theme.fontWeights.medium}
    line-height: 120%;
    color: ${(props) => props.textColor || ''};
  `
