import React from 'react'
import { Analytics } from '@genoa/analytics'
import { FLEX_ANYWHERE_HOW_YOU_PAY_CONTENT } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { FlexAnywhereCardPay, Funded, NumberCircleOne, NumberCircleTwo } from '../../../assets'
import { Headline1, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type FlexAnywhereHowYouPayProps = {
  onClickGotIt: () => void
  onCantPayViaDebit: () => void
  analyticsScreenName: Analytics.Screens
  loading: boolean
}

const FlexAnywhereHowYouPay = (props: FlexAnywhereHowYouPayProps) => (
  <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
    <Box minH="30px" />
    <Flex direction="column">
      <Headline1>{FLEX_ANYWHERE_HOW_YOU_PAY_CONTENT.HEADER}</Headline1>
      <Box minH="15px" />
      <SmallText>{FLEX_ANYWHERE_HOW_YOU_PAY_CONTENT.MAIN_TEXT}</SmallText>
      <Box minH="30px" />
      <>
        <HowYouPayInfoBoxContainer>
          <ContentContainer>
            <NumberCircleOne />
            <Box display="flex" justifyContent="center" alignContent="center">
              <Funded height="auto" width="35%" />
            </Box>
            <Box minH="24px" />
            <TextContainer>
              <SmallText textAlign="left" fontWeight="bold">
                {FLEX_ANYWHERE_HOW_YOU_PAY_CONTENT.BODY_1}
              </SmallText>
            </TextContainer>
          </ContentContainer>
        </HowYouPayInfoBoxContainer>
        <Box minH="12px" />
        <HowYouPayInfoBoxContainer>
          <ContentContainer>
            <NumberCircleTwo />
            <Box display="flex" justifyContent="center" alignContent="center">
              <FlexAnywhereCardPay height="auto" width="35%" />
            </Box>
            <Box minH="24px" />

            <TextContainer>
              <SmallText textAlign="left" fontWeight="bold">
                {FLEX_ANYWHERE_HOW_YOU_PAY_CONTENT.BODY_2}
              </SmallText>
            </TextContainer>
          </ContentContainer>
        </HowYouPayInfoBoxContainer>
      </>

      <Box minH="56px" />

      <Box>
        <PrimaryButton
          onClick={props.onClickGotIt}
          testID="ContinueButton"
          disabled={props.loading}
          processing={props.loading}
        >
          Got it
        </PrimaryButton>
      </Box>
    </Flex>
  </BasePageLayout>
)

export default FlexAnywhereHowYouPay

const HowYouPayInfoBoxContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.wildflower};
  border-radius: ${(props) => props.theme.fixedSizes.md};
  padding: ${(props) => props.theme.fixedSizes.md};
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const TextContainer = styled(Box)`
  width: 100%;
  align-items: flex-start;
`
