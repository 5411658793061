import { APPROVED_MODAL, deepReplaceContent } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { mediaDevice } from '../../../../theme/media/queries'
import { PrimaryButton, SmallText, Text, TextTitle } from '../../../components'
import { Modal, ModalActions } from '../../../components/Modal'

interface MonthlyBreakdownProps extends Omit<ApprovedModalProps, 'onClickContinue' | 'visible'> {}

export const MonthlyBreakdown = ({
  firstPaymentDisplayProportion,
  firstPaymentAmountFormatted,
  secondPaymentDisplayProportion,
  secondPaymentAmountFormatted,
  rentAmountFormatted,
  showModifyUtilizationText,
}: MonthlyBreakdownProps) => {
  const content = deepReplaceContent(APPROVED_MODAL, {
    firstPaymentAmountFormatted,
    secondPaymentAmountFormatted,
    rentAmountFormatted,
  })

  return (
    <MonthlyBreakdownContainer>
      <BreakdownTextTitle>{content.BREAKDOWN_HEADER}</BreakdownTextTitle>
      <BreakdownContainer>
        <FirstPaymentContainer w={`${firstPaymentDisplayProportion}%`}>
          <PaymentNumberText>{content.FIRST_PAYMENT}</PaymentNumberText>
          <Box />
          <PaymentAmountText data-testid="ApprovedModalFirstPaymentAmount">
            {firstPaymentAmountFormatted}
          </PaymentAmountText>
        </FirstPaymentContainer>
        <SecondPaymentContainer w={`${secondPaymentDisplayProportion}%`}>
          <PaymentNumberText>{content.SECOND_PAYMENT}</PaymentNumberText>
          <Box />
          <PaymentAmountText data-testid="ApprovedModalSecondPaymentAmount">
            {secondPaymentAmountFormatted}
          </PaymentAmountText>
        </SecondPaymentContainer>
      </BreakdownContainer>
      <SmallTextContent data-testid="ApprovedModalContentValue">{content.BREAKDOWN_BODY}</SmallTextContent>
      {showModifyUtilizationText && (
        <>
          <Box minH="10px" />
          <SmallTextContent data-testid="ApprovedModalModifyUtilizationText">
            {content.MODIFY_UTILIZATION}
          </SmallTextContent>
        </>
      )}
    </MonthlyBreakdownContainer>
  )
}

interface ApprovedModalProps {
  onClickContinue: () => void
  visible: boolean
  firstPaymentAmountFormatted: string
  firstPaymentDisplayProportion: number
  secondPaymentAmountFormatted: string
  secondPaymentDisplayProportion: number
  rentAmountFormatted: string
  showModifyUtilizationText?: boolean
}

export const ApprovedModal = ({
  onClickContinue,
  visible,
  firstPaymentAmountFormatted,
  firstPaymentDisplayProportion,
  secondPaymentAmountFormatted,
  secondPaymentDisplayProportion,
  rentAmountFormatted,
  showModifyUtilizationText,
}: ApprovedModalProps) => {
  const content = deepReplaceContent(APPROVED_MODAL, {
    firstPaymentAmountFormatted,
    secondPaymentAmountFormatted,
    rentAmountFormatted,
  })

  return (
    <Modal testID="ApprovedModal" title={content.HEADER} canClose={false} onClose={() => {}} visible={visible}>
      <HeaderTextContent data-testid="ApprovedModalSubtitleValue">{content.BODY}</HeaderTextContent>
      <Box minH="30px" />
      <MonthlyBreakdown
        showModifyUtilizationText={showModifyUtilizationText}
        firstPaymentAmountFormatted={firstPaymentAmountFormatted}
        firstPaymentDisplayProportion={firstPaymentDisplayProportion}
        secondPaymentAmountFormatted={secondPaymentAmountFormatted}
        secondPaymentDisplayProportion={secondPaymentDisplayProportion}
        rentAmountFormatted={rentAmountFormatted}
      />
      <Box minH="10px" />
      <ModalActions>
        <PrimaryButton onClick={onClickContinue} testID="ApprovedModalContinueButton">
          {content.CTA}
        </PrimaryButton>
      </ModalActions>
    </Modal>
  )
}

const HeaderTextContent = styled(SmallText)`
  display: block;
  text-align: left;
  padding-left: 22px;
  font-size: 15px;
`

const SmallTextContent = styled(SmallText)`
  font-size: 12px;
  display: block;
  padding-left: 10px;
`

const MonthlyBreakdownContainer = styled(Box)`
  text-align: left;
  min-height: 80px;
  margin-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  margin: 0px 2px;
  padding: 8px 16px 16px 16px;
`
const BreakdownTextTitle = styled(TextTitle)`
  margin: 10px;
`

const BreakdownContainer = styled(Flex)`
  text-align: center;
  min-height: 72px;
  margin-bottom: 16px;
  border-radius: 8px;
  margin: 0px 2px;
  padding: 0px 8px 16px 8px;
`

const FirstPaymentContainer = styled(Box)`
  background: ${(props) => props.theme.colors.softLilac};
  align-items: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  min-width: 40%;
  padding-top: 4px;

  @media ${mediaDevice.tablet} {
    min-width: 30%;
  }
}
`

const SecondPaymentContainer = styled(Box)`
  > p {
    color: ${(props) => props.theme.colors.white};
  }
  background: ${(props) => props.theme.colors.jewelPurple};
  align-items: center;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  min-width: 40%;
  padding-top: 4px;

  @media ${mediaDevice.tablet} {
    min-width: 30%;
  }
}
`

const PaymentNumberText = styled(SmallText)`
  font-size: 12px;
`
const PaymentAmountText = styled(Text)`
  margin-top: -4px;
  display: block;
`
