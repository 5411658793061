import React, { useEffect, useState } from 'react'
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { VerificationType } from '@genoa/domain'
import {
  GENERIC_ERROR_MODAL_CONTENT as errorContent,
  INCOME_VERIFICATION_AMOUNT_LOCAL_STORAGE,
} from '@genoa/screen-content'
import { useQueryError } from '@genoa/state-management'

import { useAuthState, useModal } from '../../../../../contexts'
import { useShowErrorMessageModal } from '../../../../../hooks'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useStartVerificationMutation } from '../../../../../modules/flexApi'
import { loggerV2, useAnalytics } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { VerifyYourIncome } from './VerifyYourIncome'

export const VerifyYourIncomeContainer = () => {
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const offerState = useOfferState()
  const { showErrorMessage } = useShowErrorMessageModal()
  const navigate = useNavigate()
  const [plaidIsOpen, setPlaidIsOpen] = useState(false)
  const [linkToken, setLinkToken] = useState('')
  const [startVerification, { isLoading, error: startVerificationError }] = useStartVerificationMutation()
  const incomeAmount = localStorage.getItem(INCOME_VERIFICATION_AMOUNT_LOCAL_STORAGE)!

  const handleOnClickContinue = () => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_BANK_LINK_CTA_CLICKED)

    if (!plaidIsOpen) {
      setPlaidIsOpen(true)
      open()
    }
  }

  const handleStartVerification = async () => {
    const response = await startVerification({
      customerId: uid!,
      verificationId: offerState.offer.onboarding_income_verification_id!,
      verification_type: VerificationType.BANK_ACCOUNT,
    }).unwrap()

    if (response.data?.link_token) {
      analytics.logEvent(Analytics.Events.IV_START_VERIFICATION_SUCCESS)

      setLinkToken(response.data.link_token)
    }
  }

  useQueryError(startVerificationError, {
    onAllErrors() {
      analytics.logEvent(Analytics.Events.IV_START_VERIFICATION_FAILURE)

      showErrorMessage(errorContent.TITLE, errorContent.SUBTITLE)
    },
  })

  useEffect(() => {
    if (linkToken === '') {
      handleStartVerification()
    }
  }, [linkToken])

  const config: PlaidLinkOptions = {
    onSuccess: () => {
      analytics.logEvent(Analytics.Events.IV_PLAID_LINK_SUCCESS, {
        verificationType: VerificationType.BANK_ACCOUNT,
      })
      navigate(Routes.Onboarding.INCOME_VERIFICATION_WAITING_OFFER)
    },
    onExit: (linkExitError) => {
      setPlaidIsOpen(false)
      if (linkExitError?.error_code) {
        analytics.logEvent(Analytics.Events.IV_PLAID_LINK_ERROR, {
          verificationType: VerificationType.BANK_ACCOUNT,
        })

        loggerV2.error(
          'Plaid Link Error',
          `${linkExitError.display_message} - ${linkExitError.error_type}: ${linkExitError.error_message}`
        )

        showErrorMessage(errorContent.TITLE, errorContent.SUBTITLE)
      } else {
        analytics.logEvent(Analytics.Events.IV_PLAID_LINK_EXIT, { verificationType: VerificationType.BANK_ACCOUNT })
      }
    },
    token: linkToken,
  }

  const { open, ready } = usePlaidLink(config)

  const handleClickUploadPaystubs = () => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_UPLOAD_PAYSTUBS_CLICKED)

    navigate(Routes.Onboarding.UPLOAD_PAYSTUBS)
  }

  return (
    <VerifyYourIncome
      analyticsScreenName={Analytics.Screens.INCOME_VERIFICATION_BANK_LINK}
      annualIncomeDollars={Number(incomeAmount)}
      onClickContinue={handleOnClickContinue}
      isLoading={isLoading}
      onClickUploadPaystubs={handleClickUploadPaystubs}
      isReady={ready}
    />
  )
}
