import React from 'react'
import { Analytics } from '@genoa/analytics'
import { SelfPayPaymentLabel } from '@genoa/domain'
import { FlexAnywherePaymentType } from '@genoa/middle-end'

import { useCustomerStatusState } from '../../../modules/flex2/use-state'
import { Congrats } from './Congrats'

export const V2CongratsContainer = () => {
  const customerStatus = useCustomerStatusState()
  const flexAnywhere = customerStatus?.customerStatus.flex_anywhere || customerStatus?.customerStatus.out_of_network
  const paymentMethod =
    flexAnywhere?.payment_type === FlexAnywherePaymentType.VIRTUAL_CARD
      ? SelfPayPaymentLabel.VIRTUAL_CARD_LONG
      : SelfPayPaymentLabel.DDA

  return <Congrats analyticsScreenName={Analytics.Screens.ONBOARDING_CONGRATS} paymentMethod={paymentMethod} />
}
