import { CustomerCookieConsentsResponse, useCustomerCookiesConsents } from '@genoa/middle-end'

import { useAuthState } from '../../contexts'
import { ConsentManagementConsentPayload } from '../amplitude'
import { useLogger } from '../logger'

type RemoteConsentResolver = () => Promise<ConsentManagementConsentPayload | undefined>

/**
 * hook returning a function that wraps the GET customer-cookies-consents endpoint, returning a ConsentManagementCookiePayload,
 * if and only if:
 * - we were able to resolve the stored consent from our backend endpoints; and,
 * - the stored consent is confirmed with a valid ISO-8601 compliant timestamp
 */
export const useRemoteCookieConsent = (): RemoteConsentResolver => {
  const logger = useLogger('useRemoteCookieConsent')

  const { uid } = useAuthState()
  const [, getCookieConsents] = useCustomerCookiesConsents()

  return async () => {
    if (uid === null) {
      return undefined
    }

    try {
      const response = await getCookieConsents({
        customerId: uid,
      })

      if (response.status !== 200) {
        // customer record could not be located; ie: no remote Customer Cookie Consent was found
        return undefined
      }

      const consent = response.data
      if (Object.keys(consent).length === 0 || !Object.prototype.hasOwnProperty.call(consent, 'confirmed')) {
        // [MRTCH-241] Empty GET /customer-cookie-consents response body indicates no remote Consent was found
        return undefined
      }

      if (!consent.confirmed) {
        // not sure why or how a non-confirmed remote Cookie Consent was stored, but because it isn't "confirmed" by the user treat it as if it weren't existent...
        logger.warn('getCookieConsents', 'unconfirmed consent was fetch from remote servers')
        return undefined
      }

      const timestamp = Date.parse(consent.dt_confirmed)
      if (Number.isNaN(timestamp)) {
        // for some reason a non ISO-8601 timestamp has been persisted on our remote servers. to be safe, let us consider this remote consent as "broken" and non-existent as well...
        logger.error('getCookieConsents', 'non ISO-8601 compliant timestamp was fetched from remote servers')
        return undefined
      }

      // all checks passed, let's return
      return mapConsentsResponseToConsentPayload(consent)
    } catch (error) {
      // we tried to fetch the Customer's remote Cookie Consents but failed
      logger.error('getCookieConsents', `error: ${error}`)
      return undefined
    }
  }
}

const mapConsentsResponseToConsentPayload = (
  response: CustomerCookieConsentsResponse
): ConsentManagementConsentPayload => {
  const timestamp = Date.parse(response.dt_confirmed)
  return {
    confirmed: response.confirmed,
    dt_confirmed: new Date(timestamp),
    purposes: {
      advertising: response.advertising,
      analytics: response.analytics,
      essential: response.essential,
      functional: response.functional,
      saleOfInfo: response.sale_of_info,
    },
    regimes: response.regimes,
  }
}
