import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { isValidZipCode } from '@genoa/domain'
import { PORTAL_ADDRESS } from '@genoa/screen-content'
import { setCustomPropertyAddressAction } from '@genoa/state-management'
import { Box } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useReduxAction } from '../../../../../hooks'
import { useAnalytics } from '../../../../../providers'
import { LocationSuggestModal } from './LocationSuggestModal'
import { PortalAddressEntry } from './PortalAddressEntry'
import { PortalAddress } from './types'
import { useLocationSuggest } from './useLocationSuggest'

const portalAddressSchema = yup.object({
  address_line_1: yup.string().required('Address is required'),
  address_line_2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup
    .string()
    .required('Zip code is required')
    .typeError('Zip code must be a number')
    .matches(/^[0-9]{5}$/, 'Please enter a valid zip')
    .test({
      name: 'is-valid-zip-code',
      message: 'Please enter a valid zip',
      test: (value) => isValidZipCode(value ?? ''),
    }),
})

interface PortalAddressEntryContainerProps {
  readonly analyticsScreen: Analytics.Screens
  readonly onContinueEvent: Analytics.Events
  readonly onContinue: () => void
}

export const PortalAddressEntryContainer = (props: PortalAddressEntryContainerProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PortalAddress>({
    mode: 'onBlur',
    resolver: yupResolver(portalAddressSchema),
  })
  const analytics = useAnalytics()
  const setCustomPropertyAddress = useReduxAction(setCustomPropertyAddressAction)
  const [showSuggestedAddressModal, setSuggestedAddressModal] = useState(false)
  const [propertyAddress, setPropertyAddress] = useState<PortalAddress | undefined>()
  const [localSuggestedAddress, setSuggestedAddress] = useState<PortalAddress | undefined>()
  const { isSubmitting, submit } = useLocationSuggest()

  const toggleSuggestedAddressModal = () => {
    analytics.logEvent(Analytics.Events.RENT_PORTAL_ADDRESS_MODAL_CLICKED, { opened: !showSuggestedAddressModal })
    setSuggestedAddressModal(!showSuggestedAddressModal)
  }

  useEffect(() => {
    analytics.logScreenView(props.analyticsScreen)
    reset()
  }, [])

  const onPressCTA = handleSubmit(async (portalAddress: PortalAddress) => {
    analytics.logEvent(props.onContinueEvent)
    setCustomPropertyAddress({
      street: portalAddress.address_line_1,
      unit: portalAddress.address_line_2,
      city: portalAddress.city,
      state: portalAddress.state,
      zipCode: portalAddress.zip,
    })

    const suggestedAddress = await submit(
      portalAddress.address_line_1,
      portalAddress.address_line_2,
      portalAddress.city,
      portalAddress.state,
      portalAddress.zip
    )

    if (!suggestedAddress) {
      props.onContinue()
      return
    }

    setPropertyAddress({
      address_line_1: portalAddress.address_line_1,
      address_line_2: portalAddress.address_line_2,
      city: portalAddress.city,
      state: portalAddress.state,
      zip: portalAddress.zip,
    })

    setSuggestedAddress(suggestedAddress)
  })

  useEffect(() => {
    localSuggestedAddress && toggleSuggestedAddressModal()
  }, [localSuggestedAddress])

  const handleContinue = () => {
    analytics.logEvent(Analytics.Events.RENT_PORTAL_ADDRESS_MODAL_SUGGESTED_CLICKED)

    localSuggestedAddress &&
      setCustomPropertyAddress({
        street: localSuggestedAddress.address_line_1,
        unit: localSuggestedAddress.address_line_2,
        city: localSuggestedAddress.city,
        state: localSuggestedAddress.state,
        zipCode: localSuggestedAddress.zip,
      })

    props.onContinue()
  }

  const handleUseOriginal = () => {
    analytics.logEvent(Analytics.Events.RENT_PORTAL_ADDRESS_MODAL_ORIGINAL_CLICKED)
    toggleSuggestedAddressModal()
    props.onContinue()
  }

  return (
    <>
      <PortalAddressEntry control={control} errors={errors} onPressCTA={onPressCTA} isSubmitting={isSubmitting} />
      <Box style={{ zIndex: 2 }}>
        <LocationSuggestModal
          visible={showSuggestedAddressModal}
          onNext={handleContinue}
          handleUseOriginalAddress={handleUseOriginal}
          onClose={toggleSuggestedAddressModal}
          portalAddress={propertyAddress}
          suggestedAddress={localSuggestedAddress}
        />
      </Box>
    </>
  )
}
