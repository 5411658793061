import { CUSTOMIZE_YOUR_SCHEDULE } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'

import { WhenDesktop, WhenPhone, WhenTablet } from '../../../../theme/media'
import {
  Headline2,
  LineDivider,
  PrimaryButton,
  ReinforcementMessage,
  ReinforcementMessageWidget,
  SmallText,
  SpinnerLoading,
} from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { ApprovedModalContainer } from '../approved'
import { PaymentInformation } from './CustomizeYourScheduleContainer'
import { PaymentScheduleBox, SecondPaymentDay } from './second-payment-days-list/SecondPaymentList'
import { SecondPaymentListContainer } from './second-payment-days-list/SecondPaymentListContainer'

type CustomizeYourSchedule = {
  onSelectSecondPaymentDay: (secondPaymentDay: SecondPaymentDay) => void
  secondPaymentDays: SecondPaymentDay[]
  isConfirmButtonDisabled: boolean
  onClickConfirmButton: () => void
  daySelected?: SecondPaymentDay
  loadingScheduleOptions?: boolean
  showReinforcementMessage?: ReinforcementMessage
  loading?: boolean
  paymentInformation: PaymentInformation
}

export const CustomizeYourSchedule = (props: CustomizeYourSchedule) => (
  <>
    <BasePageLayout>
      <Box minH="30px" />
      <Headline2 data-testid="CustomizeYourScheduleHeadline">{CUSTOMIZE_YOUR_SCHEDULE.HEADER}</Headline2>
      <Box minH="15px" />
      <SmallText data-testid="CustomizeYourScheduleBody">{CUSTOMIZE_YOUR_SCHEDULE.BODY}</SmallText>
      <Box minH="35px" />
      <PaymentScheduleBox withPaddingHorizontal>
        <Flex direction="row" justifyContent="space-between">
          <SmallText fontWeight="bold">{CUSTOMIZE_YOUR_SCHEDULE.FIRST_PAYMENT}</SmallText>
          <SmallText>{CUSTOMIZE_YOUR_SCHEDULE.FIRST_PAYMENT_INFO}</SmallText>
        </Flex>
      </PaymentScheduleBox>
      <Box minH="20px" />
      {!props.loadingScheduleOptions && props.secondPaymentDays.length > 0 && (
        <SecondPaymentListContainer
          onSelectSecondPaymentDay={props.onSelectSecondPaymentDay}
          secondPaymentDays={props.secondPaymentDays}
          daySelected={props.daySelected}
        />
      )}
      {props.loadingScheduleOptions && (
        <PaymentScheduleBox withPaddingHorizontal>
          <Flex direction="row" justifyContent="space-between">
            <SmallText fontWeight="bold">{CUSTOMIZE_YOUR_SCHEDULE.SECOND_PAYMENT}</SmallText>
          </Flex>
          <LineDivider />
          <Box minH="30px" />
          <Box marginBottom={'30px'}>{<SpinnerLoading />}</Box>
        </PaymentScheduleBox>
      )}
      <WhenDesktop>
        <Box minH="80px" />
      </WhenDesktop>
      <WhenTablet>
        <Box minH="60px" />
      </WhenTablet>
      <WhenPhone>
        <Box minH="20px" />
      </WhenPhone>
      <ReinforcementMessageWidget
        showReinforcementMessage={props.showReinforcementMessage}
        content={CUSTOMIZE_YOUR_SCHEDULE}
      />
      <Box minH="30px" />
      <PrimaryButton
        testID="CustomizeYourScheduleConfirmButton"
        disabled={props.isConfirmButtonDisabled || props.loading}
        processing={props.loading}
        onClick={props.onClickConfirmButton}
      >
        {CUSTOMIZE_YOUR_SCHEDULE.CTA}
      </PrimaryButton>
    </BasePageLayout>
    <ApprovedModalContainer paymentInformation={props.paymentInformation} />
  </>
)
