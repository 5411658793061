import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType } from '@genoa/domain'
import { deepReplaceContent, SLC_CLASSIC_APPROVED_SEPTEMBER_EXPERIMENT_CONTENT } from '@genoa/screen-content'
import { toFormattedDollars } from '@genoa/utils'
import { Stack, Text } from '@chakra-ui/react'

import { useSecureLineOfCredit } from '../../../../../hooks'
import { useHandleRegisterActionLog } from '../../../../../hooks/flex2/onboarding-status'
import { useAnalytics, useFees } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import theme from '../../../../../theme/theme'
import { Headline1, PrimaryButton } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { getApprovedPaymentInformation } from '../../customize-your-schedule/CustomizeYourScheduleContainer'
import { MonthlyBreakdown } from '../ApprovedModal'

export function ApprovedSLCClassic() {
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()
  const navigate = useNavigate()
  const fees = useFees()
  const { offerMonthlyRent } = fees
  const analytics = useAnalytics()

  const { isEnabledForSLC } = useSecureLineOfCredit()

  const {
    firstPaymentDisplayProportion,
    firstPaymentAmountFormatted,
    secondPaymentDisplayProportion,
    secondPaymentBaseAmount,
    rentAmountFormatted,
  } = useMemo(() => getApprovedPaymentInformation(fees, isEnabledForSLC), [fees, isEnabledForSLC])

  const monthylyBreakdownProps = {
    firstPaymentDisplayProportion,
    firstPaymentAmountFormatted,
    secondPaymentDisplayProportion,
    secondPaymentAmountFormatted: toFormattedDollars(secondPaymentBaseAmount),
    rentAmountFormatted,
  }
  const content = deepReplaceContent(SLC_CLASSIC_APPROVED_SEPTEMBER_EXPERIMENT_CONTENT, {
    rentAmount: toFormattedDollars(offerMonthlyRent),
  })

  async function onClick() {
    analytics.logEvent(Analytics.Events.APPROVED_SLC_SCREEN_CONTINUE_PRESSED)
    await handleRegisterActionLog(ActionLogType.APPROVE_CONFIRMATION)
    navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
  }

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_SLC_APPROVED}>
      <Stack
        mt={theme.fixedSizes.spacing_100}
        gap={theme.fixedSizes.spacing_200}
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Headline1>{content.HEADER}</Headline1>
        <Text>{content.BODY}</Text>

        <MonthlyBreakdown {...monthylyBreakdownProps} />
        <PrimaryButton processing={loadingRegisterActionLog} onClick={onClick}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </BasePageLayout>
  )
}
