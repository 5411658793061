import React from 'react'
import OTPInput from 'react-otp-input'
import { Analytics } from '@genoa/analytics'
import { CONFIRM_PIN } from '@genoa/screen-content'
import { Box, HStack, Input } from '@chakra-ui/react'

import { Headline2, SecondaryButton, SmallText, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type ConfirmPINProps = {
  phoneNumber: string
  allowResend: boolean
  onPINChange: (pin: string) => unknown
  onResendCodeClick: () => unknown
  isDisabled: boolean
  pin: string
}

export const ConfirmPIN = (props: ConfirmPINProps) => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.SIGNIN_CONFIRM_PIN}>
    <Box minH="30px" />

    <Headline2 data-testid="ConfirmPinHeading">{CONFIRM_PIN.HEADING}</Headline2>

    <Box minH="15px" />

    <Text>
      {CONFIRM_PIN.BODY_1}
      <b data-testid="PhoneNumberText">{props.phoneNumber}.</b> {CONFIRM_PIN.BODY_2}
    </Text>

    <Box minH="30px" />
    <SmallText data-testid="ConfirmPinCodeLabel">
      <b>{CONFIRM_PIN.CODE_LABEL}</b>
    </SmallText>
    <Box minH="5px" />
    <HStack>
      <OTPInput
        numInputs={6}
        shouldAutoFocus
        value={props.pin}
        inputStyle={{
          width: '48px',
          height: '48px',
          padding: 0,
          textAlign: 'center',
          marginRight: '0.25rem',
          marginLeft: '0.25rem',
          background: 'inherit',
        }}
        placeholder=""
        inputType="number"
        renderInput={(inputProps, idx) => (
          <Input
            focusBorderColor="brand.900"
            data-testid={`OtpDigit${idx + 1}`}
            disabled={props.isDisabled}
            size="lg"
            spellCheck={false}
            {...inputProps}
          />
        )}
        onChange={props.onPINChange}
      />
    </HStack>

    <Box minH="30px" />

    <Box>
      <SecondaryButton
        disabled={!props.allowResend}
        onClick={props.onResendCodeClick}
        testID="ConfirmOtpResendCodeButton"
        variant={'small'}
      >
        {CONFIRM_PIN.SEND_CODE_CTA}
      </SecondaryButton>
    </Box>
  </BasePageLayout>
)
