import React from 'react'
import { Analytics } from '@genoa/analytics'

import { DocumentVerification } from './DocumentVerification'

export const DocumentVerificationContainer = () => {
  return (
    <DocumentVerification
      analyticsScreenName={Analytics.Screens.DOCUMENT_VERIFICATION}
      onClickContinue={() => {
        console.log('Continue')
      }} // TODO: Replace with Socure SDK call
      isLoading={false} // TODO: Figure out if we need a loading state
    />
  )
}
