import { Analytics } from '@genoa/analytics'
import { NOT_APPROVED } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'

import { HeroChecklist as NotApprovedImage } from '../../assets'
import { Headline1, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

const imageSize = {
  maxHeight: '280px',
  minHeight: '200px',
}

export const NotApproved = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_NOT_APPROVED}>
    <Flex direction="column" align="center">
      <Box minH={'60px'} />

      <Flex {...imageSize}>
        <NotApprovedImage />
      </Flex>

      <Headline1 textAlign="center">{NOT_APPROVED.HEADER}</Headline1>

      <Box minH={'24px'} />

      <Text textAlign="center" color={'dusk'} data-testid="NotApprovedBody">
        {NOT_APPROVED.BODY}
      </Text>
    </Flex>
  </BasePageLayout>
)
